<template>
  <div class="pucontainer">
    <div class="navtitle">
      <h5 class="blod">
        {{
          $store.state.pageType.id == 1
            ? $t('common.men')
            : $store.state.pageType.id == 2
              ? $t('common.women')
              : $t('common.kids')
        }}&nbsp;{{ $t('header_menu.brand_a_z') }}
      </h5>
      <div>
        <span @click="$router.push('/')">
          {{
            $route.query.typeId == 1
              ? $t('common.men')
              : $route.query.typeId == 2
                ? $t('common.women')
                : $t('common.kids')
          }}
        </span>
        <span @click="$router.push('/goodslist')">
          &nbsp;/
          {{
            $route.query.typeId == 3
              ? $t('common.boy')
              : $route.query.typeId == 4
                ? $t('common.girl')
                : $t('common.collections')
          }}
        </span>
        <span>&nbsp;/ {{ $t('common.brands') }}</span>
      </div>
    </div>
    <!-- 品牌筛选 -->
    <div class="goodsCon">
      <div class="navleft">
        <div class="serachBran">
          <q-input dense v-model="searchVal" :label="$t('brands_az.search_brand')">
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </div>
        <div class="azBox">
          <p class="indxbar blod">A-Z</p>
          <div class="zlist">
            <span
              v-for="(item, i) in brandlist"
              :key="i"
              @click="selectBrand(item)"
            >{{ item.label }}</span>
            <span @click="azlist = brandlist">{{ $t('common.all') }}</span>
          </div>
        </div>
      </div>
      <div class="navright">
        <div class="like-brand">
          <p class="like-title">{{ $t('brands_az.like_brands') }}</p>
          <div class="likeBrand-cont">
            <div class="like-item" v-for="(item) in brandData" :key="item.id">
              <div class="p" v-if="item.isgz">
                <div @click="focusBrandHandle(item)">
                  <span class="iconfont" v-if="item.isgz">&#xe707;</span>
                  <span class="iconfont" v-else>&#xe708;</span>
                </div>
                <router-link :to="{path: '/goodslist', query:{params: getUrl(item.guid)}}" @click="check">{{ item.title }}</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="allList">
          <div class="alitem" v-for="(items, index) in azlist" :key="index">
            <h5 class="blod">{{ items.label }}</h5>
            <div class="p" v-for="(iteml) in items.children" :key="iteml.id">
              <div @click="focusBrandHandle(iteml)">
                <span class="iconfont" v-if="iteml.isgz">&#xe707;</span>
                <span class="iconfont" v-else>&#xe708;</span>
              </div>
              <router-link :to="{path: '/goodslist', query:{params: getUrl(iteml.guid)}}" @click="check">{{ iteml.title }}</router-link>
            </div>
          </div>
          <div v-if="!azlist.length">{{ $t('brands_az.no_find') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { focusBrand, currency2 } from '@/api/common'
import store from '../../store'
import _ from 'lodash'

export default {
  data () {
    return {
      azlist: [],
      brandlist: [],
      brandData: [],
      searchVal: ''
    }
  },
  computed: {
    getUrl () {
      return function (guid) {
        const params = _.cloneDeep(store.state.goodsModule.originalParams)
        params.htype = store.state.pageType.id
        params.generalattributeid = JSON.stringify([{ 品牌: [guid] }])
        return encodeURI(JSON.stringify(params))
      }
    },
    pageType () {
      return store.state.pageType
    }
  },
  watch: {
    // 搜索品牌
    searchVal (val) {
      const arr = []
      this.brandData.forEach(item => {
        if (item.title.toLowerCase().indexOf(val.toLowerCase()) >= 0) {
          arr.push(item)
        }
      })
      this.azlist = this.filterBrand(arr)
    },
    pageType () {
      this.getBrandApi()
    }
  },
  methods: {
    check () {
      store.commit('setOnlyBrand', true)
    },
    async getBrandApi () {
      const res = await currency2({
        id: store.state.pageType.id
      })
      if (res.status === 1) {
        this.brandData = _.get(res, 'item[0].generalattribute[0].item') || []
        this.azlist = this.filterBrand(this.brandData)
        this.brandlist = this.azlist
      }
    },
    selectBrand (item) {
      this.azlist = [item]
    },
    async focusBrandHandle (item) {
      const res = await focusBrand({
        Brandguid: item.guid,
        type: this.$store.state.pageType.id
      })
      if (res.status === 1) {
        item.isgz = true
      } else {
        item.isgz = false
      }
    },
    filterBrand (val) {
      const map = new Map()
      let temp = []
      val.forEach(o => {
        const t = o.title.substr(0, 1)
        if (!map.get(t)) {
          temp = []
          map.set(t, temp)
        }
        temp.push(o)
      })
      const arr = []
      map.forEach((o, i) => {
        const obj = {
          label: i,
          children: o
        }
        arr.push(obj)
      })
      return arr
    }
  },
  mounted () {
    this.getBrandApi()
  }
}
</script>

<style lang="scss" scoped>
.navtitle {
  text-align: center;
  margin-bottom: 0px;
  h5 {
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: bold;
  }
  span {
    cursor: pointer;
  }
}
.goodsCon {
  padding: 10px;
  display: flex;
  .navleft {
    width: 250px;
    flex-shrink: 0;
    cursor: pointer;
    margin-top: 30px;
    .serachBran {
      width: 80%;
      margin: 0 auto;
    }
    .azBox {
      margin-top: 20px;
      margin-bottom: 56px;
      .indxbar {
        margin-left: 10%;
        margin-bottom: 10px;
      }
      .zlist {
        span {
          display: inline-block;
          width: 25%;
          font-size: 14px;
          padding: 10px 0;
          text-align: center;
          &:hover {
            background-color: #f4f4f4;
          }
        }
      }
    }
  }
  .navright {
    flex: 1;
    margin-left: 50px;
    .like-brand {
      margin-top: 30px;
      .like-title {
        margin-bottom: 15px;
        font-weight: bold;
        font-family: Microsoft YaHei;
      }
      .likeBrand-cont {
        column-count: 3;
        .like-item {
          break-inside: avoid-column;
          .p {
            line-height: 20px;
            cursor: pointer;
            color: #000;
            width: fit-content;
            display: flex;
            align-items: center;
            a {
              padding-left: 5px;
            }
            .iconfont {
              font-size: 18px;
            }
          }
        }
      }
    }
    .allList {
      width: 100%;
      column-count: 3;
      margin-top: 50px;
      .alitem {
        break-inside: avoid-column;
        margin-bottom: 30px;
        h5 {
          font-size: 18px;
          margin: 0;
        }
        .p {
          line-height: 20px;
          cursor: pointer;
          color: #000;
          margin-bottom: 0px;
          width: fit-content;
          display: flex;
          a {
            padding-left: 5px;
          }
          .iconfont {
            font-size: 18px;
          }
        }
      }
    }
  }
}
</style>
